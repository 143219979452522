@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import 'variables';

@font-face {
  font-family: 'OpenSans';
  src: url(font/OpenSans.ttf);
}

$breakpoints: (
  'small': 480px,
  'medium': 768px,
  'large': 1024px,
  'xlarge': 1200px,
);

@mixin responsive-margin {
  margin: 0px 5% 2% 5%;
  @media (min-width: map-get($breakpoints, 'small')) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: map-get($breakpoints, 'medium')) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: map-get($breakpoints, 'large')) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media (min-width: map-get($breakpoints, 'xlarge')) {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-family: $font-family-base;
  margin: 0;
  padding: 0;
  background-color: $color-background;
}

.container {
  margin-left: calc(
    90px + 20px
  ); /* Platz für die Sidebar und zusätzlichen Abstand */
  margin-right: 20px; /* Abstand zum rechten Bildschirmrand */
  padding: 20px;
}
//Fonts
//Login Überschrift
h1 {
  font-size: $font-size-xxlarge;
  color: $color-text;
  font-weight: $font-weight-medium;
}

//Login Überschrift
h1 {
  font-size: $font-size-xxlarge;
  color: $color-text;
  font-weight: $font-weight-medium;
}

//Große Überschrift
h2 {
  font-size: $font-size-xlarge;
  color: $color-text;
  font-weight: $font-weight-bold;
  font-weight: $font-weight-bold;
}
//Hellblaue Card Überschrift
h3 {
  font-size: $font-size-large;
  color: $color-text-secondary;
  font-weight: $font-weight-semibold;
}
//Filter Überschrift
h4 {
  font-size: $font-size-base;
  color: $color-text;
  font-weight: $font-weight-bold;
}

//Tabellenüberschrift
h5 {
  font-size: $font-size-small;
  color: $color-text;
  font-weight: $font-weight-bold;
}

p {
  font-size: $font-size-small;
  color: $color-text;
  font-weight: $font-weight-light;
}
.divider {
  border: none;
  height: 1px;
  background-color: #dbe1f8;
  margin: 10px 0px 25px 0px;
}

.secondary-button {
  @include flex-center;
  padding: 10px 20px;
  color: $color-primary;
  font-size: $font-size-small;
  font-weight: $font-weight-medium;
  border: 1px solid $color-primary;
  border-radius: $border-radius-medium;
  background: rgba(255, 0, 0, 0);
  cursor: pointer;
  &:hover {
    background-color: $secondary-button-hover;
  }
}

//PrimeNG Overrides
.p-multiselect {
  min-width: 190px;
  max-width: 190px;
  .p-multiselect-header {
    display: none;
  }
  .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background-color: $color-background-secondary;
    color: $color-text;
    border-radius: 4px;
    span {
      font-size: 12px;
    }
    .p-icon-wrapper {
      color: $color-text;
    }
  }
}
.p-multiselect-panel {
  .p-multiselect-header {
    display: none;
  }
  .p-multiselect-items {
    .p-multiselect-item:hover,
    .p-focus {
      background: $color-background-secondary;
    }
  }
}

.p-multiselect-label,
.p-inputtext {
  font-size: 14px;
}

.p-dialog .p-dialog-header {
  padding: 30px 35px 0px 35px;
}

.p-dialog .p-dialog-content {
  padding: 0px 35px 30px 35px;
}

.p-dropdown {
  min-width: 190px;
}

.p-paginator .p-dropdown {
  width: 90px;
  min-width: 0px;
}
.p-paginator {
  font-size: 14px;
  button {
    font-size: 14px;
  }
}

p-chart {
  display: flex;

  canvas {
    width: 500px !important;
    height: 220px !important;
    margin-right: 50px;
  }
}
